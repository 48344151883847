.styled-value {
  color: var(--Grey-9, #212121);
  font-size: 18px;
  font-weight: 400;
}

.styled-key {
  color: var(--Grey-6, #7a7a7a);
  font-size: 14px;
  font-weight: 300;
}

.value-percentege {
  color: var(--Grey-9, #212121);
  font-size: 20px;
  font-weight: 700;
}

.box {
  margin-inline: 200px;
}

@media screen and (max-width: 1600px) {
  /* .styled-value {
    font-size: 15px;
  }
  .styled-key {
    font-size: 11px;
  }
  .value-percentege {
    font-size: 16px;
  } */
}