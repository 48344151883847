.tab-card {
  padding: 16px;
  width: 100%;
  background: #e6f7fd;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  position: relative;
}

.step-div {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 5px;
}

.step {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 5px;
}

.tab-name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
