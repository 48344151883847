.cardDetails {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 50px;
  border-radius: 8px;
  padding: 16px 24px;
  height: 182px;
  position: relative;
}

.backImage {
  position: absolute;
  right: 0;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1px;
}
.nameDesign {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}
.statusDesign {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  z-index: 2;
}
.valueDesign {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  z-index: 2;
}
